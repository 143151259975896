.sLC1,
.sLC2,
.sLC3,
.sLC3W,
.sLC4,
.sLC7,
.sLC8,
.ssLC2 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sLC1 {
  width: 100vw;
  height: 87vh;
  overflow-y: hidden;
}
.sLC2 {
  height: 90%;
  width: 90%;
  overflow-y: auto;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid #c7c8cc;
}
.ssLC2 {
  height: 90%;
  width: 90%;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid #c7c8cc;
}

.sLC3,
.sLC3W {
  width: 90%;
  margin-top: 5px;
  min-height: 60px;
  margin-bottom: 5px;
  border: 1px solid #b4b4b8;
  border-radius: 5px;
  justify-content: space-around;
  background-color: #f2efe5;
}
.sLC3W {
  border: 1px solid #c5ebaa;
  background-color: #c5ebaa;
}
.sLC4 {
}
.sLC41,
.sLC42 {
  color: black;
  font-size: larger;
  font-weight: bold;
}
.sLC42 {
  margin-left: 5px;
}
.sLC5 {
}
.sLC5 > button {
  height: 35px;
  width: 100px;
  color: black;
  font-size: large;
  font-weight: 900;
  border-radius: 5px;
  background-color: #e3e1d9;
  border: 1px solid #e3e1d9;
}
.sLC6 {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1001;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(0, 0, 0, 0.75);
}
.sLC7 {
  margin-bottom: 15px;
}
.sLC8 {
  width: 100%;
}
.sLC8 > button {
  height: 40px;
  width: 200px;
  color: black;
  font-size: large;
  font-weight: 900;
  margin-top: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  background-color: #f28585;
  border: 1px solid #f28585;
}
.sLC9 {
  width: 100%;
  height: 100%;
}
.sLC10 {
  top: 0;
  right: 0;
  z-index: 1003;
  cursor: pointer;
  position: absolute;
}
.sLC10Icn {
  width: 40px;
  height: 40px;
}
.sLC2Back {
  height: 40px;
  width: 100%;
  margin-bottom: 5px;
  border: 1px solid #ccc;
}
.sLC2BackIcn {
  height: 40px;
  width: 40px;
  color: black !important;
  cursor: pointer;
}
