body,
html {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
  color: white;
}
.App {
  height: 100vh;
  max-width: 100%;
  max-width: 400px;
}
