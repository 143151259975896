.dfjcac,
.dffdcjcac {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dffdcjcac {
  flex-direction: column;
}
.ws1 {
  height: 100%;
  width: 100%;
}
.ws2 {
  height: 100%;
  width: 100%;
  background-image: url("../assets/images/rb.jpeg");
  background-position: center;
  background-size: cover;
}

.overlay {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.wsc {
  color: white;
  width: 98%;
  height: 100%;
  bottom: 30%;
  margin-left: 1%;
  justify-content: flex-end;
}

.wsc1 {
  font-size: larger;
  font-weight: 900;
}

.wsc2 {
  width: 70%;
  height: 40px;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 5px;
  margin-top: 18px;
  color: #000;
  font-weight: 700;
  outline: none;
}

.wsc3 {
  height: 40px;
  width: 70%;
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-top: 10px;
  background-color: #efefef;
  font-weight: 700;
  margin-bottom: 10%;
  color: #000;
}

.up1 {
  height: 100%;
}

.up2 {
  width: 98%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.up3 {
  font-weight: 700;
  font-size: large;
}

.up4 {
  width: 98%;
  display: flex;
  margin-top: 9px;
  margin-bottom: 18px;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: 5px;
}

.up4 > div {
  flex: 1;
  border: 1px solid #efefef;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.selDiv {
  background-color: #efefef;
  color: #000;
}

.up5 {
  border: 1px solid #efefef;
  width: 98%;
  height: 40px;
  color: #000;
  font-weight: 500;
  padding: 5px;
  outline: none;
}

.up6 {
  width: 98%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.up6 > button {
  width: 100%;
}

.ld1 {
  height: 100%;
}
.ld2 {
}
.ld3 {
  margin-top: 10px;
  font-weight: 700;
}
.ld4 {
  margin-top: 5px;
  font-weight: 500;
}

.qs1 {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.qs2 {
  margin-top: 30%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.qs21 {
  height: 40px;
  padding-right: 5px;
  color: #000;
  width: 100px;
  background-color: #efefef;
  border: 1px solid #efefef;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-weight: 500;
}
.qs22 {
  height: 40px;
  color: #000;
  padding: 5px;
  background-color: #efefef;
  border: 1px solid #efefef;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-weight: 700;
}
.qs22DN {
  visibility: hidden;
}
.qs21 > div {
  height: 100%;
  color: #000;
  font-weight: 600;
  font-size: large;
  padding-left: 5px;
}
.qs3 {
  margin-top: 90px;
}

.iqsn1 {
  width: 98%;
  margin-left: 1%;
}
.iqsn2 {
  height: 80px;
  background-color: #efefef;
  color: #000;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 5px;
  font-style: normal;
  font-weight: 500;
}
.iqsn3 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.iqsn4 {
  margin-top: 50px;
  height: 40px;
  width: 90%;
  margin-left: 5%;
  justify-content: space-between !important;
}

.iqsnbtnc,
.iqsnbtnn {
  width: 40%;
  height: 100%;
  width: 100%;
  color: #000;
  font-style: normal;
  font-weight: 700;
}

.iqsnbtnc {
  margin-right: 5%;
}

.iqsnbtnn {
}

.iqsn5 {
  justify-content: space-between;
}
.iqsn5 > button {
  background-color: #efefef;
  color: #000;
  width: 45%;
  margin: 5px;
  height: 40px;
  border: 1px solid #efefef;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  padding: 5px;
}

.iqsnTA {
  background-color: #86a7fc !important;
  border: 1px solid #86a7fc !important;
  color: white !important;
}

.iqsnCA {
  background-color: #bfea7c !important;
  border: 1px solid #bfea7c !important;
  color: #000 !important;
}

.iqsnWA {
  background-color: #e78895 !important;
  border: 1px solid #e78895 !important;
  color: #000 !important;
}

.qsnsAnswrd {
  width: 98%;
  background-color: #efefef;
  padding: 10px;
  margin-left: 1%;
  margin-top: 30%;
  border-radius: 5px;
}
.qsnsAnswrd1 {
  margin-top: 5px;
}
.qsnsAnswrd1,
.qsnsAnswrd3 {
  color: #000;
  font-weight: 500;
}
.qsnsAnswrd2 {
  color: #000;
  font-weight: 700;
}
.qsnsAnswrd3 {
  margin-top: 20px;
  text-align: justify;
}
.qsnsAnswrd4 {
  width: 98%;
}
.qsnsAnswrd4 > button {
  background-color: #c3e2c2;
  height: 40px;
  width: 60%;
  border-radius: 5px;
  border: 1px solid transparent;
  margin-top: 20px;
  color: #000;
  font-weight: 700;
  margin-bottom: 5px;
}
